import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import LazyLoad from "react-lazyload"

import useContentful from "../../utils/hooks/useContentful"
import { Device, MetadataReadonly } from "../../types/contentfulTypes"
import { mockupsCount } from "../../data/MockupsData"

import Layout from "../../components/layout/Layout"
import * as TextStyles from "../../components/styles/TextStyles"
import TextSection from "../../components/sections/TextSection"
import CollectionsSection from "../../components/sections/CollectionsSection"
import CollectionCard from "../../components/cards/CollectionCard"
import Helpers from "../../utils/Helpers"
import CollectionCardList from "../../components/lists/CollectionCardList"

interface Props {
  data: {
    contentfulMetadata: MetadataReadonly
  }
}

const DesktopMockups = (props: Props) => {
  const { data } = props
  const { getCollections } = useContentful()
  const [collections, setCollections] = useState<Device[]>([])
  const desktopCount = mockupsCount["macOS"] + mockupsCount["iPadOS"]

  useEffect(() => {
    const fetchCollections = async () => {
      const macCollection = await getCollections("Apple", "macOS")
      const ipadCollection = await getCollections("Apple", "iPadOS")

      if (macCollection && ipadCollection) {
        const fetchedCollections = macCollection.concat(ipadCollection)
        const sortedCollections = Helpers.sortArrayByProperty(
          fetchedCollections,
          "releaseDate"
        ).reverse()
        setCollections(sortedCollections)
      }
    }

    fetchCollections()
    return () => setCollections([])
  }, [])

  return (
    <Layout {...data}>
      <Wrapper>
        <Container>
          <StaticImage
            className="illustration"
            src="../../images/illustrations/illustration13.webp"
            alt="illustration"
          />
          <TextContainer>
            <Title>{desktopCount}</Title>
            <TextSection
              title="iPad and Mac Mockups"
              description="Our latest library packs iPad, Pro Display XDR, MacBook and iMac device mockups that comes with various angles to present your designs."
            />
          </TextContainer>
        </Container>
        <LazyLoad>
          <CollectionCardList collections={collections} />
          <CollectionsSection collections={collections} />
        </LazyLoad>
        <CollectionContainer>
          <CollectionCard
            title="iOS"
            link="/collections/ios"
            image="../../images/illustrations/collection-ios.png"
          />
          <CollectionCard
            title="Android"
            link="/collections/android"
            image="../../images/illustrations/collection-android.png"
          />
        </CollectionContainer>
      </Wrapper>
    </Layout>
  )
}

export default DesktopMockups

export const query = graphql`
  query DesktopMockupsQuery {
    contentfulMetadata(slug: { eq: "desktop-mockups" }) {
      ...MetadataFragment
    }
  }
`

const Wrapper = styled.div`
  padding: 200px 0px;
`

const Container = styled.div`
  display: grid;
  max-width: 1234px;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;

  @media (max-width: 1050px) {
    grid-template-columns: 1fr;
  }

  .illustration {
    max-width: 620px;
    margin: 0 auto;
  }
`

const TextContainer = styled.div`
  display: grid;
  height: fit-content;
  justify-content: center;
  align-items: center;
  gap: 15px;

  .purchase {
    display: none;
  }
`

const Title = styled(TextStyles.H1)`
  font-style: normal;
  font-weight: 300;
  font-size: 140px;
  line-height: 167px;
  padding: 0 20px;

  @media (max-width: 414px) {
    font-size: 100px;
  }
`

const CollectionContainer = styled.div`
  display: grid;
  max-width: 880px;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  justify-content: center;
  justify-items: center;
  gap: 20px;
  padding: 180px 20px 0;
  margin: 0 auto;
`
